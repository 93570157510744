import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import Typewriter from 'typewriter-effect'
import LoopVideo from './assets/vid_fon.mp4'
import Glitch from 'components/glitch'
import Swal from 'sweetalert2'
import ticket from 'sections/images/gw-01.png'
import msj from 'sections/images/gw-08.png'
import Cookies from 'universal-cookie';
import Idioma from 'sections/idiomas/contents.json'
import $ from 'jquery';
import AnimatedHeading from 'components/animated-heading'
import { Row, Col, Container } from 'react-bootstrap'
import tw from 'sections/images/gw-06.png'
import fb from 'sections/images/gw-05.png'
import dsc from 'sections/images/gw-07.png'
import AnimationContainer from 'components/animation-container'
import gif from 'sections/team/teamgif.gif'
import RevealContent from 'components/reveal-content'
import imagen1 from 'sections/images/fondos/col_gd.gif'                                
import imagen2 from 'sections/images/z4.png'
import Counter from 'components/counter'
import { faWindows } from '@fortawesome/free-brands-svg-icons'
import Web3 from 'web3';
import ABI from 'sections/contracts/Cryptoversox.json'
import coleccion_video from 'sections/videos/Bots-merge_.mp4'
const url = gif;
class Video_list2 extends React.Component {

    constructor(props) {
        const cookies = new Cookies();
        super(props)
        let titulo=""
        let contenido=""
        let descarga=""
        let a=cookies.get('idioma')
          if(a=="ingles"){
             titulo=Idioma.ingles.metamask.titulo;
             contenido=Idioma.ingles.metamask.contenido;
             descarga=Idioma.ingles.metamask.descarga;
        }else{
          titulo=Idioma.espanol.metamask.titulo;
          contenido=Idioma.espanol.metamask.contenido;
          descarga=Idioma.espanol.metamask.descarga;
        }
    
        this.state={
            account:"",
      tiulos:titulo,
      contenidos:contenido,
      descargas:descarga,
      contract:null,
      totalsupply:0,
      disponibles:0,
      tengo:0,
        }
    }
    updateDimensions = () => {
        if (this.state.height !== window.innerHeight) {
            this.setState({height: window.innerHeight})
        }
        if (this.state.width !== window.innerWidth) {
            this.setState({width: window.innerWidth})
        }
    }

    async loadweb3(){
        if(window.ethereum){
            window.web3=new Web3(window.ethereum)
            await window.ethereum.enable()
        }
        else if(window.web3){
            window.web3=new Web3(window.web3.currentProvider)
        } else{
            Swal.fire({
                title: "<h4 style='color:ff00ff'>"+this.state.tiulos+"</h4><br><hr style='border-top: 1px solid #ff00ff;'>",
                width: 600,
                padding: '3em',
                color: '#fff',
                text: this.state.contenidos,
                footer: '<a href="https://metamask.io/download/" target="_blank" style="color:ff00ff">'+this.state.descargas+'</a>',
                background: '#000',
                confirmButtonText: 'OK',
                confirmButtonColor: '#ff00ff',
                backdrop: `
                  rgba(0,0,0,0.1)
                  url("/images/nyan-cat.gif")
                  left top
                  no-repeat
                `
              })
        }
    }

    async loadblockchaindata(){
        const web3=window.web3
        //cargar cuentas
        const accounts=await web3.eth.getAccounts();
        this.setState({account: accounts[0]})
        const newworkID = '5777'

        const networkdata=ABI.networks[newworkID]
        if(networkdata){
           
            const abi=ABI.abi
            const address =networkdata.address
            const contract = new web3.eth.Contract(abi,address)
            this.setState({contract})
            const totalsupply = await contract.methods.totalSupply().call()
            
            this.setState({totalsupply})

            const disponibles = await contract.methods.MAX_SUPPLY().call()
           
            this.setState({disponibles})
            
            const tengo = await contract.methods.balanceOf(this.state.account).call()

            
             this.setState({tengo})
        }
    }


    async mint_presale(){
            const precio = await this.state.contract.methods.price().call()
         const fundit = await  this.state.contract.methods.mintToken(1).send({
            from: this.state.account, 
            value: precio ,
            gasLimit: 250000
           })
    .then(res => 
          console.log('Success', res))
    .catch(err => console.log(err)) 
    }


    
    async componentDidMount() {
       await  this.loadweb3()
       await this.loadblockchaindata();
       
        this.setState({height: window.innerHeight, width: window.innerWidth})
        window.addEventListener('resize', this.updateDimensions)
        document.body.addEventListener('mousemove', (e) => {
            var amountMovedX = (e.clientX * -.1 / 8);
            var amountMovedY = (e.clientY * -.1 / 8);
            var x = document.getElementsByClassName("parallax-hero-item");
            var i;
            for (i = 0; i < x.length; i++) {
              x[i].style.transform='translate(' + amountMovedX + 'px,' + amountMovedY + 'px)'
            }
        });
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    render() {

        const Section = styled.section`
            position: relative;
            .particles {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            background-size: cover;
            background-repeat: no-repeat;
        `
        const CounterComponent = styled.div`
            margin: 10px 0;
            text-align: center;
            @media (max-width:767px) {
                margin: 50px 0;
                text-align:center;
            }
            .value {
                font-size: 120px;
                font-family: Teko;
                color: #fff;
                line-height: 90px;
            }
            .text {
                font-size: 20px;
                color: #fff;
            }
            .symbol {
                color: #04e5e5;
                position: absolute;
                font-size: 39px;
                top: -28px;
                @media (max-width: 500px) {
                    top: 0;
                }
            }

        `

        const VideoContainer = styled.div`
            width: 100%;
           
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;
            video {
                position: absolute;
                
            }
            @media (max-width: 1023px) {
                width: 100%;
                
            }
        `
        const LeftCol = styled(Col)`
        display: flex;
        align-items: baseline;
        justify-content: center;
        @media (max-width: 50px) {
            margin-bottom: 2px;
            margin-right: 4px;
        }
    `
    const AboutContainer = styled(Container)`
            padding: 40px 0;
            @media (max-width: 500px) {
                padding: 20px 0;
                
            }
        `
        const ImageContainer = styled.div`
        border-radius: 20px;
        overflow: hidden;
     
        @media (max-width: 767px) {
            margin-bottom: 50px;
        }
    `
   
    
    
    
    

    const AnimatedShadow = keyframes`
            0%   {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
            10%  {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
            20%  {box-shadow: 0 28px 60px rgba(239, 78, 123, .5);}
            30%  {box-shadow: 0 28px 60px rgba(161, 102, 171, .5);}
            40% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
            50% {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
            60% {box-shadow: 0 28px 60px rgba(7, 179, 155, .5);}
            70% {box-shadow: 0 28px 60px rgba(111, 186, 130, .5);}
            80% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
            90% {box-shadow: 0 28px 60px rgba(16, 152, 173, .5);}
            100% {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
        `
    const Separator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #04e5e5;
            margin-bottom: 20px;
        `
        const Heading = styled.div`
            .glitch {
                font-size: 110px;
                line-height: 140px;
                font-weight: 600;
                color: #fff;
                @media (max-width: 767px) {
                    font-size: 40px;
                    line-height: 50px;
                }
            }
        `

        const SubHeading = styled.h2`
            font-size: 18px;
            font-weight: 300;
            color: #ccc;
            text-transform: uppercase;
            letter-spacing: 4px;
        `
        const Type = styled.div`
            font-size: 50px;
            line-height: 50px;
            color: #fff;
            text-transform: uppercase;
            margin-left: 6px;
            @media (min-width:768px) and (max-width:1500px) {
                font-size: 23px;
                line-height: 20px;
            }
            @media (max-width:767px) {
                font-size: 20px;
                line-height: 20px;
            }
            span {
                font-family: Teko;
            }
        `
        const gradientAnimation = keyframes`
            0% {
              background-position: 15% 0%;
            }
            50% {
              background-position: 85% 100%;
            }
            100% {
              background-position: 15% 0%;
            }
          `
          

        const HeadingBox = styled.div`
            height: 500px;
            width: 900px;
            margin: auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 5px;
                background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
                background-size: 300% 300%;
                clip-path: polygon(0% 100%, 10px 100%, 10px 10px, calc(100% - 10px) 10px, calc(100% - 10px) calc(100% - 10px), 10px calc(100% - 10px), 10px 100%, 100% 100%, 100% 0%, 0% 0%);
            }
            &.animate:after {
                animation: ${gradientAnimation} 2s ease-in-out infinite;
            }
            @media (max-width: 767px) {
                height: 350px;
            }
        `

        const Resume = styled.a`
            color: #fff;
            text-decoration: none;
            border-bottom: 2px solid;
            font-weight: 300;
            transition: .5s;
            margin-top: 40px;
            &:hover {
                color: #fff;
                text-decoration: none;
                border-color: #04e5e5;
            }
        `
        const ImageBox = styled.div`
        
      
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        overflow: hidden;
        @media (max-width:767px) {
            min-height: 400px;
            padding: 0;
        }
    `

    const example = keyframes`
    0% { 
        width:600px;
    }
    
    50% {
        width:590px;
    }
    
    100% {
        width:600px;
    }
        `
        const example1 = keyframes`
        0% { 
            width:300px;
        }
        
        50% {
            width:290px;
        }
        
        100% {
            width:300px;
        }
            `

    const Image = styled.img`
   
        width:600px;
      
        @media (min-width: 767px) { 
            animation-name:  ${example};
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
        @media (max-width: 767px) {
            width: 300px;
            example1
            animation-name:  ${example1};
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
       
    `

    const Image1 = styled.img`
   
        width:300px;
      
       
        @media (max-width: 767px) {
            width: 150px;
        }
       
    `

    const Submit = styled.button`
        
    height: 50px;
    width: 200px;
    position: relative;
    border: none;
    overflow: hidden;
    transition: .5s;
    &::after {
      position: absolute;
      content:'';
      display: inline-block;
      background: rgba(255, 0, 255, 1);
      background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(195 62 195 / 63%) 25%, rgba(255, 0, 255, 1) 51%, rgba(0, 0, 0, 1) 100%);
      height: 100%;
      width:  140%;
      top: 0;
      left: 0;
      transition: .5s;
    }
    
    &:hover {
      &::after {
        transform: translateX(-20%);
      }
    }
    span {
      font-size: 15px;
      position: relative;
      top: 0;
      -webkit-text-stroke: 0.5px black;
      color: #fff;
      z-index: 10;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
`
    

        function aparecer(id){
            desaparecer()
                var ids="#"+id;
                $(ids).show()
              
            }
    
        function desaparecer(){
            for (let index = 1; index <= 6; index++) {
                var ids="#p"+index
                $(ids).hide()
            }
        }
        return (
            <Section id="home">
                <VideoContainer style={{height: `${this.state.width > 550 ? this.state.height : 1500}px`}}>
                    <video autoPlay="autoplay" loop="loop" muted style={{height: `${this.state.width >= 1023? "110%": "80%"}`}}>
                        <source src={LoopVideo} type="video/mp4" />
                    </video>
                   
                        
                       
                    <AboutContainer>
                   
        <div class="container pt-5 mt-5">
                          <div class="row pt-5 mt-5 ">
                                <div class="col-md-4 " >
                                  
                                     <div class="row justify-content-center">
                                         <p class="text-pink text_medium ml-3">EXPLORE MORE</p>
                                     </div>
                                     <div class="row justify-content-center">
                                          <img src={imagen1} class="img-fluid"></img>
                                     </div>
                                </div>

                                <div class="col-md-6 ">
                                     <div >
                                         <h1 class="text-verde_crypto text_extra ">OBTÉN <br/> TU NFT </h1>
                                     </div>
                                     <div class="row">
                                         <p class="text-cyan text_small2 text-center ">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                              sed do eiusmod tempor incididunt ut labore et dolore magna
                                               aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                                ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                                Duis aute irure dolor in reprehenderit in voluptate velit
                                                 esse cillum dolore eu fugiat nulla pariatur.  
                                                 <br></br> 
                                                 <Submit className='my-5' onClick={()=>this.mint_presale()}><span>
                                            <a type="button" className='text-light'  >
                                            
                                                        MINT
                                            </a>
                                          </span></Submit> 
                          
                                         </p>
                                     </div>
                                </div>

                                <div class="col-1">

                                </div>
                          </div>


                          <div class="row">
                        <div class="col-md-3 col-6" onClick={()=> window.location.href = `/${"coleccion"}`}>
                                <AnimationContainer animation="fadeIn" delay={1000}>
                                    <CounterComponent>
                                        <Counter value={this.state.tengo} duration={5} delay={1000} animation={true} symbol="+" text="You have Cryptoversox" />
                                    </CounterComponent>
                                </AnimationContainer>
                        </div>
                        <div class="col-md-3 col-6">
                        <AnimationContainer animation="fadeIn" delay={1000}>
                                    <CounterComponent>
                                        <Counter value={this.state.totalsupply} duration={5} delay={1000}  animation={true}   symbol="+" text="Minted"/>
                                    </CounterComponent>
                                </AnimationContainer>
                        </div>
                        <div class="col-md-3 col-6">
                        <AnimationContainer animation="fadeIn" delay={1000}>
                                    <CounterComponent>
                                        <Counter value={this.state.disponibles} duration={5} delay={1000} symbol="+" text="Available" animation={true} />
                                    </CounterComponent>
                                </AnimationContainer>
                        </div>
                        <div class="col-md-3 col-6">
                        <AnimationContainer animation="fadeIn" delay={1000}>
                                    <CounterComponent>
                                        <Counter value={10} duration={5} delay={1000}  animation={true}  symbol="+" text="Price"/>
                                    </CounterComponent>
                                </AnimationContainer>
                        </div>
                    </div>

                    

        </div>

                           <br/>
                           
                     


                          


        

                   </AboutContainer>
                                
                    
                   


                </VideoContainer>
            </Section>

            
        )

        
    }

    shapes() {

        const MoveUp = keyframes`
            0% { 
                transform: translateY(0);
            }
            100% {
                transform: translateY(6px);
            }
        `

        const MoveDown = keyframes`
            0% { 
                transform: translateY(0);
            }
            100% {
                transform: translateY(40px);
            }
        `
        const Shape = styled.img`
            position: absolute;
            height: 50px;
            &.move-up {
                animation: ${MoveUp} 5s infinite  alternate;
            }
            &.move-down {
                animation: ${MoveDown} 5s infinite  alternate;
            }
            @media (max-width: 767px) {
                height: 20px;
            }
        `
        


        return this.props.shapes.map((value, index) => {
            return (
                <Shape
                    style={{
                        left: `${(index+1) * 10}%`,
                        bottom: `${Math.random() *
                        (+((index+1) % 2 === 0 ? 10 : 90) - +((index+1) % 2 === 0 ? 5 : 80)) +
                        +((index+1) % 2 === 0 ? 5 : 80)}%`,
                    }}
                    key={index}
                    src={value.node.childImageSharp.fluid.src}
                    alt="shape"
                    className={Math.floor(Math.random() * 10) % 2 === 0 ? "move-up" : "move-down"}
                />

                
            )


            
        })


    }
}

export default props => (
    <StaticQuery
      query={graphql`
      query {
        shapes: allFile(filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "shapes"}}) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
      }    
      `}
      render={({ shapes }) => <Video_list2 shapes={shapes.edges} {...props} />}
    />
  )